/**
 * Mocking client-server processing
 */
const _articles = [
  {
    'id': 1,
    'title': 'How to use maesto email and newsletter template in mailchimp.',
    'meta': 'Adding an e-mail and newsletter template from Maesto to the Mailchimp service and using the Drag & Drop system in the default configurator.',
    'image': [
      {
        'src': 'https://blog.maesto.net/images/thumb/maesto/mailchimp-470x260.png',
        'alt': 'maesto mailchimp template',
        'source': 'https://blog.maesto.net/images/thumb/maesto/mailchimp-365x260.png',
        'source2': 'https://blog.maesto.net/images/thumb/maesto/mailchimp-365x185.png',
        'original': 'https://blog.maesto.net/images/maesto/mailchimp.png'
      }
    ],
    'video': [
      {
        'show': 'true',
        'link': '<iframe width="100%" height="470" src="https://www.youtube.com/embed/vpsvwGOt4LU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      }
    ],
    'lead': 'The guide is dedicated to users who have problems with using and editing e-mail templates on the Mailchimp website. We got quite a lot of inquiries about using this site and usually there weren\'t any problems with our templates from Envato Market. Specifically, they concerned basic functionalities such as adding photos, changing the font color, adding links, or even using the drag and drop system built into the configurator of this site.',
    'content': [
      { 'section': 'Adding an e-mail and newsletter template from Maesto to the Mailchimp service and using the Drag & Drop system in the default configurator.' },
      { 'section': 'The guide is dedicated to users who have problems with using and editing e-mail templates on the Mailchimp website. We got quite a lot of inquiries about using this site and usually there weren\'t any problems with our templates from Envato Market. Specifically, they concerned basic functionalities such as adding photos, changing the font color, adding links, or even using the drag and drop system built into the configurator of this site.' },
      { 'section': 'The first step is to download the template files purchased on Envato Market or from our Maesto configurator. After unpacking the zip package, enter the folder and select the "3_mailchimp" folder. Once we entered in this folder, we choose the way of import, there are three possibilities.' },
      { 'section': '1. Add as HTML file,' },
      { 'section': '2. Paste the template as HTML code,' },
      { 'section': '3. Download the template as a package in .zip format.' },
      { 'section': 'I prefer the third way, because it\'s the safest and uploads photos from the folder too. Due to the laws, we added only logo image and thumbnails with specific image sizes to make it easier for users to supplement them.' },
      { 'section': 'After logging in maichimp account, click templates, then create templates and import zip. Enter the name of the template and select the file from disk, now click, upload. Wait for problems with mailchimp service, often we get the end of the connection or error of uploading files. Be patient, because uploading takes a long time, and sometimes you can get strange errors. As a developer, I am able to understand the source of these problems, it\'s occur due to the popularity of the site and millions of uploads, e-mail, photos and templates. I recommend waiting for a feedback message from the mailchimp server.' },
      { 'section': 'The correct completion of uploading will take us to the preview page of the template. This is where the problems with using and editing an e-mail template begin. I suggest to only save the template (save and exit) or go to the last section and change something, and save. After saving, all sections to edited section will work normally and the rest below will be hidden. But there is nothing to worry about, we are interested in editing the template at the level of e-mail campaign editing. So now we click the main site panel on campaigns and create campaigns. A popup will show to choose your campaign destination, of course we choose e-mails and give the name. Now, click on the content and on the right the design e-mail button, click saved templates from the new buttons. We choose our newly created e-mail skin, I have uploaded the Remo template on the film, so I do it.' },
      { 'section': 'At this stage, as you can see in the film, only the first section or all sections to the editted section are shown. To add a new section, click the + button or delete - after hovering over the section, there is also an option to hide. After clicking plus and waiting, we add a duplicate section, now select the module to load in this section from the drop-down list. After selecting the appropriate sections, we can proceed to supplement the pictures and content of the e-mail. The arrows that appear are used to drag the sections in the template, which gives you the freedom to edit the appearance layout.' },
      { 'section': 'Our team hopes this guide will help many of our users.' },
      { 'section': 'Greetings from Maesto Team.' }
    ],
    'slug': 'maesto-email-and-newsletter-template-in-mailchimp',
    'outerlink': ''
  },
  {
    'id': 2,
    'title': 'How to add photos in the Maesto configurator.',
    'meta': 'To add a photo in the Maesto configurator, just paste the link to the photo online (direct link) in the appropriate place of the edited section.',
    'image': [
      {
        'src': 'https://blog.maesto.net/images/thumb/maesto/maesto-470x260.png',
        'alt': 'maesto builder',
        'source': 'https://blog.maesto.net/images/thumb/maesto/maesto-365x260.png',
        'source2': 'https://blog.maesto.net/images/thumb/maesto/maesto-365x185.png',
        'original': 'https://blog.maesto.net/images/maesto/maesto.png'
      }
    ],
    'video': [
      {
        'show': 'true',
        'link': '<iframe width="100%" height="470" src="https://www.youtube.com/embed/qJs52dJ83KQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      }
    ],
    'lead': 'Our team of programmers, building the configurator Maesto had to face many legal problems. It is the legal aspects that have shaped the system of adding photos to the template. In the film uploaded on YouTube, we show how to add a photo to the banner that is the background of the content. I chose the Amare store template for the demo.',
    'content': [
      { 'section': 'Our team of programmers, building the configurator Maesto had to face many legal problems. It is the legal aspects that have shaped the system of adding photos to the template. In the film uploaded on YouTube, we show how to add a photo to the banner that is the background of the content. I chose the Amare store template for the demo.' },
      { 'section': 'To add a photo in the Maesto configurator, just paste the link to the photo online (direct link) in the appropriate place of the edited section.' },
      { 'section': 'The link can be copied from sites that offer photo sharing with the ability to share. Most often, the first link copied on these pages refers to the image preview and is not a direct link.' },
      { 'section': 'The video shows that only the second link opened in the new card is the right one. We click the right mouse button on the picture, select the menu "open the graphic in a new tab", go to the new card and copy its link. This link is the right one.' },
      { 'section': 'After adding photos, missing content and saving the template version, you can finally go to export your email creatives. We currently have 5 versions of export, ready for use on most dedicated e-mail marketing solutions.' },
      { 'section': 'Our team hopes this guide will help many of our users.' },
      { 'section': 'Greetings from Maesto Team.' }
    ],
    'slug': 'how-to-add-images-in-maesto-builder',
    'outerlink': ''
  },
  {
    'id': 3,
    'title': 'Activation of the maesto email template with the purchase code from envato market.',
    'meta': 'After downloading, we enter this file and look for the Item Purchase Code header. We mark the code and enter our account on the maesto website.',
    'image': [
      {
        'src': 'https://blog.maesto.net/images/thumb/maesto/maesto-470x260.png',
        'alt': 'envato market purchase code',
        'source': 'https://blog.maesto.net/images/thumb/maesto/maesto-365x260.png',
        'source2': 'https://blog.maesto.net/images/thumb/maesto/maesto-365x185.png',
        'original': 'https://blog.maesto.net/images/maesto/maesto.png'
      }
    ],
    'video': [
      {
        'show': 'true',
        'link': '<iframe width="100%" height="470" src="https://www.youtube.com/embed/3y3myBRDa_A" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      }
    ],
    'lead': 'Hello, today I want to show you how to add the activation code from envato to the selected skin, e-mail template on the maesto website.',
    'content': [
      { 'section': 'Hello, today I want to show you how to add the activation code from envato to the selected skin, e-mail template on the maesto website.' },
      { 'section': 'Of course, to do this, you must create an account at maesto.net. Go to maesto.net and click "Do not have an account?". Now we fill in the data and the correct e-mail address.' },
      { 'section': 'Successful registration will display information on the new page about the activation link. You will receive a verification link by e-mail, after clicking it will be possible to log in to the site.' },
      { 'section': 'To activate the e-mail template, we need an activation code from envato market. We enter the envato market and choose sections to download from the menu.' },
      { 'section': 'There we have a button on the right side of the selected item called license certificate and purchase. Click the button, we start to download the txt file with data.' },
      { 'section': 'After downloading, we enter this file and look for the Item Purchase Code header. We mark the code and enter our account on the maesto website.' },
      { 'section': 'After logging in, on the first page we have on the left our already active templates and on the right you can buy or activate.' },
      { 'section': 'We click, of course, the use purchase code, and after switching to the new page, add the code that we copied to the right place earlier. If the code is wrong, the message pops up, otherwise it will redirect you to the first page and you will see our template on the left.' },
      { 'section': 'Thats all we can click it to builder and choose the version of the template that we want to use.' },
      { 'section': 'Greetings from Maesto Team.' }
    ],
    'slug': 'activation-email-template-with-purchase-code-envato-market',
    'outerlink': ''
  },
  {
    'id': 4,
    'title': 'How to upload maesto email template to campaign monitor system.',
    'meta': 'Today I want to show you how to add our template to the campaign monitoring system step by step.',
    'image': [
      {
        'src': 'https://blog.maesto.net/images/thumb/maesto/html-470x260.png',
        'alt': 'e-mail template campaign monitor',
        'source': 'https://blog.maesto.net/images/thumb/maesto/html-365x260.png',
        'source2': 'https://blog.maesto.net/images/thumb/maesto/html-365x185.png',
        'original': 'https://blog.maesto.net/images/maesto/html.png'
      }
    ],
    'video': [
      {
        'show': 'true',
        'link': '<iframe width="100%" height="470" src="https://www.youtube.com/embed/ZzAzeJAllrw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      }
    ],
    'lead': 'Today I want to show you how to add our template to the campaign monitoring system step by step.',
    'content': [
      { 'section': 'Hello, today I want to show you how to add our template to the campaign monitoring system.' },
      { 'section': 'The first step is to log in to the campaign monitor and select the My templates tab. Then click submit your own HTML code. Enter the name of the template you want to display. Now you have to click select the file in the HTML page section and select the html file with our template. Due to the fact that we can not upload photos with an html file at the same time, they should be packed into the zip format. Once we have the file ready, just click and select it in the second section.' },
      { 'section': 'Finally, click add template and wait for 60 seconds for the thumbnail generation message to appear. We have a ready email template for the campaign website.' },
      { 'section': 'Greetings from Maesto Team.' }
    ],
    'slug': 'how-to-upload-maesto-email-template-to-campaign-monitor-system',
    'outerlink': ''
  }
]

export default {
  getArticles (cb) {
    setTimeout(() => cb(_articles), 100)
  }
}
