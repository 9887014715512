const _header = [
  {
    'logo': 'https://blog.maesto.net/images/maesto/maesto-logo.svg',
    'logo_alt': 'Maesto',
    'class': 'maesto',
    'style': '2',
    'size': '4'
  }
]

export default {
  getHeader (cb) {
    setTimeout(() => cb(_header), 100)
  }
}
