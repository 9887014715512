







import Vue from "vue";
// import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Component, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class Footer extends Vue {
  @State(state => state.hole) stateFooter;
  @Action("hole/getFooter") actionGetFooter;

  // styleUrl: string;

  // constructor() {
  //   super();
  //   this.styleUrl = "";
  // }

  // loadStyle() {
  //   const x = this.actionGetFooter();
  //   x.then(result => {
  //     if (x) this.styleUrl = this.stateFooter.footer.style;
  //     require("./../../styles/footer/footer-style-" + this.styleUrl + ".scss");
  //   }).catch(err => {});
  // }

  created() {
    this.actionGetFooter();
  }

  mounted() {
    window.addEventListener("load", function(event) {
      const cookies = document.querySelector('.Cookie')
      const getCookie = localStorage.getItem('cookie:accepted')
      console.log(getCookie)
      if(cookies && getCookie == 'true'){
        cookies.remove();
      }
    });
  }

  loadAsyncData() {
    this.actionGetFooter();
    return this.stateFooter.footer;
  }

  get footer() {
    // this.loadStyle();
    return this.loadAsyncData();
  }
}
