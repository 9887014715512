/**
 * Mocking client-server processing
 */
const _privatepolicy = [
  {
    'id': 1,
    'title': 'Private policy',
    'meta': 'Private policy',
    'keywords': '',
    'content': `
    <p>
        (“Maesto”, www.blog.maesto.net) is committed to the privacy
        of those utilising the Maesto service (“Service”).
        Our Privacy Policy is designed to assist you in understanding what information we collect,
        how it is utilised and how to contact us if you have any queries.
    </p>
    <h2>Our Usage of Information Stored by the Service</h2>
    <div class="sub-items">
        <h3> Cookies</h3>
        <p> Cookies are used to personalise your experience of the Service. For example, storing your
            session details for the convenience of auto-login.
            We also use cookies to evaluate your usage of the Service, so that we can improve
            performance and user enjoyment.
            To disable cookies, please consult the support documentation of your preferred browser.
        </p>

        <h3> Individual User Data</h3>
        <p> To provide unique logins to the service we must store access credentials in the form of
            email addresses and a custom password.
            For security, this password is hashed, so is unavailable in plain text form.
        </p>
    </div>
    <h2> Data Storage</h2>
    <p> To store your data securely we have taken a number of steps to restrict loss, misuse,
        and unauthorised access or disclosure. These actions take into account the sensitivity of the
        information we collect,
        process and store, and the current state of technology.</p>
    <h2> Changes to our Privacy Policy</h2>
    <p> We may periodically update this policy. We will notify you about significant changes
        in the way we treat personal information.</p>

    <h2>Deleting your data</h2>
    <p>
        We honor all deletion requests. If you would like to delete your account or any piece of data,
        please email
        <a href="mailto:support@maesto.net">support@maesto.net</a>.
        We comply with all deletion requests within 30 days and will send you a receipt upon completion
    </p>

    <h2>Changing your data</h2>
    <p>
        If you would like to change any piece of data on your account, please email <a href="mailto:support@maesto.net">support@maesto.net</a>.
        We comply with all data-change requests within 30 days and will send you a receipt upon
        completion
    </p>

    <h2>Third-parties who we integrate with</h2>
    <p>
        We also make sure of the following third-party softwares. Please see their respective privacy
        policies for details.
    </p>
    <ul>
        <li>
            Google Analytics <a href="https://analytics.google.com">(analytics.google.com)</a>
        </li>
    </ul>

    <h2> Questions</h2>
    <p>
        Any questions about this Privacy Policy should be addressed to
        <a href="mailto:support@maesto.net">support@maesto.net</a>
    </p>`,
    'slug': 'private-policy'
  }
]

export default {
  getPrivatepolicy (cb) {
    setTimeout(() => cb(_privatepolicy), 100)
  }
}
