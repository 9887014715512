import homeVue from './../../components/homepage/Homepage.vue'
import listVue from './../../components/list/List.vue'
import articlesDetailsVue from './../../components/articlesDetails/ArticleDetails.vue'
import regulationsVue from './../../components/regulations/Regulations.vue'
import privatepolicyVue from './../../components/privatepolicy/Privatepolicy.vue'

const _routes = [
  {
    name: 'home', path: '/', component: homeVue, children: [
      {
        name: 'regulations',
        path: '/regulations',
        component: regulationsVue,
        props: true
      },
      {
        name: 'privatepolicy',
        path: '/private-policy',
        component: privatepolicyVue,
        props: true
      }
    ], props: true
  },
  { name: 'blog', path: '/blog', component: listVue },
  { name: 'slug', path: '/:slug', component: articlesDetailsVue, props: true }
]

export default {
  getRoutes (cb, site) {
    setTimeout(() => { cb(_routes), site('maesto') }, 100)
  }
}
