const _footer = [
  {
    'copyright': [
      {
        'id': '1',
        'content': 'Copyright 2018 maesto.net',
        'size': '8'
      },
      {
        'id': '2',
        'content': '<a href="mailto:support@maesto.net">support@maesto.net</a>',
        'size': '4'
      },
      {
        'id': '3',
        'content': null
      },
      {
        'id': '4',
        'content': null
      }
    ],
    'class': 'maesto',
    'style': '2'
  }
]

export default {
  getFooter (cb) {
    setTimeout(() => cb(_footer), 100)
  }
}
