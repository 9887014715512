const _nav = [
  {
    'class': 'maesto',
    'style': '1',
    'size': '8',
    'primaryMenu': [
      {
        'id': '102',
        'name': 'privatepolicy',
        'to': 'private-policy',
        'text': 'Private Policy',
        'link': 'false',
        'follow': 'false'
      }
    ]
  }
]

export default {
  getNav (cb) {
    setTimeout(() => cb(_nav), 100)
  }
}
