/**
 * Mocking client-server processing
 */
const _regulations = [
  {
    'id': 1,
    'title': 'Regulations',
    'meta': 'xxx',
    'keywords': 'xxx',
    'content': '',
    'slug': 'regulations'
  }
]

export default {
  getRegulations (cb) {
    setTimeout(() => cb(_regulations), 100)
  }
}
