const _analitycs = [
  {
    'active': true,
    'type' : 'ga',
    'id': 'UA-86282483-2'
  }
]

export default {
  getAnalitycs (cb) {
    setTimeout(() => cb(_analitycs), 100)
  }
}
